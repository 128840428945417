<template>
    <form class="box" @submit.prevent="sendFile">
        <b-loading v-model="isUploadingFile"/>
        <b-field label="Selecciona el tipo de carga">
            <div class="d-flex is-justify-content-center">
                <div 
                    @click.prevent="selectedOperation = loadType.id"
                    :class="{selected: selectedOperation == loadType.id}"
                    v-for="loadType in loadTypes" 
                    :key="loadType.id" 
                    class="load-type">
                    <img :src="loadType.icon">
                    <h4>
                        {{ loadType.name }}
                    </h4>
                </div>
            </div>
        </b-field>
        <div v-if="selectedOperation">
            <b-message type="is-info" has-icon size="is-default" closable>
                Tip: los nombres de catálogos se dan de alta
                <router-link to="/catalogos">
                    aquí.
                </router-link>
            </b-message>
            <b-field label="* Nombre del catálogo">
                <b-autocomplete
                    v-model="catalogName"
                    :data="filteredCatalogList"
                    field="catalogName"
                    placeholder="Buscar.."
                    icon="search"
                    open-on-focus
                    clearable
                    @select="option => selectedCatalog = option">
                    <template #empty>No results found</template>
                </b-autocomplete>
            </b-field>
            <div v-if="hasSelectedFile" class="d-flex has-text-centered">
                <b-notification
                    @close="dropFiles = []"
                    :closable="!isUploadingFile"
                    class="w-100 d-block m-auto"
                    type="is-primary is-light">
                    <figure class="m-auto image is-128x128">
                        <img :src="require(`@/assets/icons/${selectedFile.ext == 'csv' ? 'csv.png' : 'xls.png'}`)">
                    </figure>
                    <b class="mt-3 d-block is-6">{{ selectedFile.name }}</b>
                    <small class="d-block is-7">{{ selectedFile.size }}kb</small>
                    <b-progress
                        v-show="isUploadingFile"
                        class="p-absolute bottom-0" 
                        :value="uploadProgress" 
                        show-value 
                        format="percent" 
                        size="is-medium" 
                        type="is-success" 
                        :rounded="false"
                    />
                </b-notification>
            </div>
            <b-field v-else>
                <b-upload v-model="dropFiles" accept=".xlsx" multiple expanded drag-drop>
                    <section class="section">
                        <div class="content has-text-centered">
                            <p><b-icon icon="upload" size="is-large"/></p>
                            <p>Arrastra tu plantilla <b>excel</b> o da clic aquí</p>
                        </div>
                    </section>
                </b-upload>
            </b-field>
            <br/>
            <div v-if="selectedOperation == 'inventory'">
                <b-field label="Fecha de carga">
                    <b-datepicker
                        placeholder="Selecciona una fecha"
                        icon="calendar-today"
                        v-model="selectedDate">
                    </b-datepicker>
                </b-field>
                <br/>
                <b-field label="Hora de carga">
                    <b-timepicker
                        placeholder="Selecciona una hora"
                        icon="clock"
                        v-model="selectedTime">
                    </b-timepicker>
                </b-field>
            </div>
            <br/>
            <b-button
                native-type="submit"
                class="mt-3 ml-auto d-block"
                :type="canUpload ? 'is-light is-primary' : 'is-danger'" 
                icon-left="file-arrow-up" 
                :label="canUpload?'Subir':'Completa la info'" 
                :disabled="!canUpload"
                :loading="isUploadingFile"
            />
        </div>
    </form>
</template>

<script>
import * as XLSX from 'xlsx';
export default {
    computed: {
        filteredCatalogList(){
            return this.catalogList.filter(item=>{
                return item
                    .catalogName
                    .toString()
                    .toLowerCase()
                    .indexOf(this.catalogName) >= 0
            })
        },
        canUpload(){
            // Validate is loadtype is id product or inventory
            if( this.selectedOperation === 'product' ){
                return (
                    this.catalogName.length > 0 && 
                    this.hasSelectedFile
                )
            }
            return (
                this.catalogName.length > 0 && 
                this.hasSelectedFile &&
                this.selectedDate != null &&
                this.selectedTime != null
            )
        },
        hasSelectedFile(){
            return this.dropFiles.length > 0
        },
        selectedFile(){
            if( this.hasSelectedFile ){
                let f = this.selectedFileForSend

                return {
                    name: f.name,
                    size: f.size,
                    ext: f.name.substr( f.name.indexOf('.')+1) 
                }
            }
            return null
        },
        selectedFileForSend(){
            return this.hasSelectedFile ? this.dropFiles[0] : null
        },
        backendAPI(){
            return this.$store.state.backendAPI
        }
    },
    mounted(){
        this.getCatalogs()
    },
    data() {
        return {
            selectedDate: null,
            selectedTime: null,
            catalogList: [],
            dropFiles: [],
            uploadProgress: 0,
            isUploadingFile: false,
            catalogName: '',
            selectedCatalog: null,
            selectedOperation: null,
            loadTypes: [
                {
                    id: 'product',
                    icon: require('@/assets/icons/list.svg'),
                    name: 'Carga de productos'
                },
                {
                    id: 'inventory',
                    icon: require('@/assets/icons/inventory.svg'),
                    name: 'Carga de Inventarios'
                }
            ]
        }
    },
    methods: {
        deleteDropFile(index) {
            // Abort
            if( this.isUploadingFile )return;

            this.dropFiles.splice(index, 1)
        },
        async sendFile(){
            if( !this.hasSelectedFile )return;

            this.$buefy.toast.open({
                message: 'Estamos enviando tu archivo...',
                position: 'is-bottom-right'
            })

            if (this.selectedOperation === "inventory") {
                console.log("processInventory***********************************)))))");
                let dataFromXlsx = await this.convertXlsxToJson(this.selectedFileForSend);
                console.log("dataFromXlsx-->", dataFromXlsx);

                let dataSendJoy = {
                    "date": `${this.selectedDate}`,
                    "hour": `${this.selectedTime}`,
                    "body_csv": dataFromXlsx,
                    "catalog_id": this.selectedCatalog.catalogId
                }
                console.log("dataSendJoy----->", dataSendJoy);
    
                this.$store.dispatch(
                    'processInventory',
                    dataSendJoy
                ).then(res => {
                    console.log("res-->", res);
                    this.$buefy.dialog.confirm({
                        title: '¡Listo!',
                        message: 'Tu archivo fue cargado correctamente',
                        confirmText: 'Ir al inventario',
                        cancelText: 'Aceptar',
                        type: 'is-success',
                        hasIcon: true,
                        onConfirm: () => this.$router.push('/reporte-inventario'),
                        onCancel: ()=> this.resetAll()
                    })
                }).catch(err => {
                    console.log("err-->", err);
                    this.$buefy.dialog.confirm({
                        title: `Algo falló con estatus ${xhr.status}`,
                        message: err + 'Por favor, asegúrate de llenar correctamente la plantilla. ¿Quieres volver a intentarlo?',
                        confirmText: 'Si, otra vez',
                        type: 'is-danger',
                        hasIcon: true,
                        onConfirm: () => this.sendFile(),
                        onCancel: ()=> this.resetAll()
                    })
                })
                return;
            }


            let file = this.selectedFileForSend
            // product_ / inventory_
            let fileName = this.selectedOperation + '_' +window.getUniqueId()
            let fileExtension = 'xlsx'
            let originalFileName = this.selectedFile.name
            let userEmail = this.$keycloak.tokenParsed.email

            let API = this.backendAPI

            let xhr = new XMLHttpRequest();
            xhr.open(
                'PUT', 
                `${API.rootUrl}${API.services.uploadCatalogTemplate.url}${fileName}.${fileExtension}`, 
                true
            )

            // Custom headers
            xhr.setRequestHeader('Content-Type', 'application/octet-stream');
            xhr.setRequestHeader("x-api-key", API.apiKey);
            xhr.setRequestHeader("x-user-email", userEmail);
            xhr.setRequestHeader("x-file-name", originalFileName);
            // Joy 2.0 headers
            xhr.setRequestHeader("x-catalog-name", btoa(this.selectedCatalog.catalogName));
            xhr.setRequestHeader("x-uuid-catalog-name", this.selectedCatalog.catalogId);
            xhr.setRequestHeader("x-catalog-version", this.selectedCatalog.version);

            // Handlers
            xhr.onloadstart = ()=>{
                this.uploadProgress = 0
                this.isUploadingFile = true
            }

            xhr.onload = ()=>{
                this.isUploadingFile = false
            }

            xhr.upload.onprogress = (e)=>{
                if (e.lengthComputable)
                    this.uploadProgress = Math.ceil(((e.loaded) / e.total) * 100);
            }

            xhr.onreadystatechange = ()=>{
                if (xhr.readyState === 4) {
                    let response = window.parseJwt(xhr.responseText)

                    console.log( xhr, response )

                    if (xhr.status === 200 || xhr.status === 201) {
                        // Success

                        this.$buefy.dialog.confirm({
                            title: '¡Listo!',
                            message: 'Tu archivo fue cargado correctamente',
                            confirmText: 'Ir al historial',
                            cancelText: 'Aceptar',
                            type: 'is-success',
                            hasIcon: true,
                            onConfirm: () => this.$router.push('/'),
                            onCancel: ()=> this.resetAll()
                        })

                        this.resetAll()

                    } else {
                        // Failed
                        this.isUploadingFile = false
                        this.$buefy.dialog.confirm({
                            title: `Algo falló con estatus ${xhr.status}`,
                            message: (response && response.errors ? response.errors + '. ' : '') + 'Por favor, asegúrate de llenar correctamente la plantilla. ¿Quieres volver a intentarlo?',
                            confirmText: 'Si, otra vez',
                            type: 'is-danger',
                            hasIcon: true,
                            onConfirm: () => this.sendFile(),
                            onCancel: ()=> this.resetAll()
                        })
                    }
                }
            }

            xhr.send(file)
        },
        resetAll(){
            this.dropFiles = []
            this.catalogName = ''
            this.selectedCatalog = null
            this.uploadProgress = 0
            this.isUploadingFile = false
        },
        getCatalogs(){
            this.isLoading = true
            this.$store.dispatch(
                'getCatalogList', 
                `?itemslimitnumber=100`
            )
            .then(res=>{
                let data = res.data
                this.catalogList = data.catalogs.sort((a,b)=>{
                    return b.createdAtTimestamp - a.createdAtTimestamp
                })
            })
            .catch(err=>{
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: 'Hubo un error: ' + err.toString(),
                    position: 'is-bottom-right'
                })
            })
            .finally(()=>{
                this.isLoading = false
            })
        },


        async convertXlsxToJson(file) {
            // Retorna una nueva promesa
            return new Promise((resolve, reject) => {
                // Crea una nueva instancia de FileReader
                const reader = new FileReader();

                // Define qué hacer cuando se haya leído el archivo
                reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, {type: 'binary'});

                // Suponiendo que tu archivo XLSX tiene una sola hoja y quieres leer la primera hoja
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                // Convierte los datos de la hoja a JSON
                const json = XLSX.utils.sheet_to_json(worksheet);
                console.log(json); // Aquí tienes tus datos en formato JSON

                // Resuelve la promesa con los datos JSON
                resolve(json);
                };

                // Define qué hacer en caso de error
                reader.onerror = (error) => {
                reject(error);
                };

                // Lee el archivo como datos binarios
                reader.readAsBinaryString(file);
            });
        }
    }
}
</script>

<style scoped lang="scss">
.load-type{
    background: #f1f1f1;
    border-radius: 6px;
    text-align: center;
    padding: 1em;
    cursor: pointer;
    margin: 1em;
    width: 14em;
    transition: all 0.1s;
    border: solid 2px transparent;

    h4{
        font-weight: bold;
        color: black;
        text-transform: uppercase;
        margin-top: 6px;
    }

    img{
        width: 6em;
        display: block;
        margin: auto;
    }
}
.load-type:hover{
    transform: scale3d(1.06, 1.06, 1.06);
    background: #e8e5ff;
}
.load-type.selected{
    background: #f9f42e;
    border: solid 2px black;
}
</style>