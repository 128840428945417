<template>
    <div class="box overflow-x-auto">
        <b-field label="Registros a mostrar">
            <b-select placeholder="Selecciona" v-model="logEntriesPerPage">
                <option value="5">Últimos 5</option>
                <option value="10">Últimos 10</option>
                <option value="25">Últimos 25</option>
                <option value="50">Últimos 50</option>
                <option value="100">Últimos 100</option>
            </b-select>
            <b-button @click="getLogs" class="ml-auto d-block" type="is-primary" icon-left="refresh" :loading="isLoadingLogs">Actualizar</b-button>
        </b-field>
        <b-message type="is-warning">
            <b>Últimos movimientos</b>
        </b-message>
        <b-table :data="logs" :loading="isLoadingLogs" default-sort="createdAt" default-sort-direction="desc">
            <b-table-column field="filetype" label="Tipo" v-slot="props" centered sortable>
                <b class="is-size-7">
                    {{ (props.row.filetype || 'Products').toUpperCase() }}
                </b>
            </b-table-column>

            <b-table-column field="userEmail" label="Usuario" v-slot="props" centered searchable sortable>
                <span class="tag">
                    <b-icon icon="user"/>&nbsp;{{ props.row.userEmail }}
                </span>
            </b-table-column>

            <b-table-column field="catalogName" label="Catálogo" v-slot="props" centered searchable sortable>
                <small class="is-size-7">
                    {{ props.row.catalogName }}
                </small>
            </b-table-column>

            <b-table-column field="catalogVersion" label="Versión" v-slot="props" centered sortable>
                <small class="is-size-7 tag" v-if="props.row.catalogVersion">
                    {{ props.row.catalogVersion }}
                </small>
            </b-table-column>

            <b-table-column field="status" label="Estatus" v-slot="props" centered sortable>
                <span class="tag" :class="{
                    'is-info': props.row.status == 'PROCESSING',
                    'is-success': props.row.status == 'PROCESSED' || props.row.status == 'FILEUPLOADSUCCESS',
                    'is-danger': props.row.status == 'ERROR' || props.row.status == 'FILEVALIDATIONERROR',
                    'is-warning': props.row.status == 'PROCESSEDWITHERROR',
                }">
                    {{ _getStatusDisplay(props.row.status) }}
                </span>
            </b-table-column>

            <b-table-column field="itemsLoaded" label="Items cargados" v-slot="props" centered sortable>
                <span class="tag is-size-7">
                    {{ props.row.itemsLoaded || 0 }}
                </span>
            </b-table-column>

            <b-table-column field="createdAt" label="Fecha de publicación" v-slot="props" sortable searchable centered>
                <span class="tag">
                    <b-icon icon="cloud-arrow-up"/>&nbsp;{{ props.row.createdAt }}
                </span>
            </b-table-column>

            <b-table-column field="uploaded_file" label="Archivo" v-slot="props" centered>
                <a @click.prevent="getS3Link(props.row)" class="tag is-primary is-light">
                    <b-icon icon="up-right-from-square"/>&nbsp;Excel
                </a>
            </b-table-column>

            <b-table-column label="Detalles" v-slot="props" centered>
                <router-link :to="`/historico/movimiento/${props.row.keyfilenameId}`">
                    <b-button icon-right="eye" type="is-light is-primary" title="Ver detalles" />
                </router-link>
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">Nada para mostrar</div>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    mounted(){
        this.getLogs()
    },
    data() {
        return {
            logs: [],
            isLoadingLogs: false,
            logEntriesPerPage: 100,
        }
    },
    watch: {
        logEntriesPerPage(){
            window.debounce(this.getLogs, 300)
        }
    },
    methods: {
        getLogs(){
            this.isLoadingLogs = true
            this.$store.dispatch(
                'getUploadedTemplatesLog', 
                `?itemslimitnumber=${this.logEntriesPerPage}`
            )
            .then(res=>{
                let data = res.data
                this.logs = data.files
            })
            .catch(err=>{
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: 'Hubo un error: ' + err.toString(),
                    position: 'is-bottom-right'
                })
            })
            .finally(()=>{
                this.isLoadingLogs = false
            })
        },
        getS3Link(item){
            this.$buefy.toast.open({
                message: 'Estamos obteniendo la liga del archivo...',
                position: 'is-bottom-right'
            })
            this.$store.dispatch('getS3ofUploadedTemplate', item.keyfilenameId)
            .then(res=>{
                let data = res.data
                this.$buefy.snackbar.open({
                    message: `¡Listo!, ya tenemos tu archivo (${item.keyfilenameId.substr(0,6)})`,
                    position: 'is-bottom-right',
                    actionText: 'Descargar',
                    cancelText: 'Cancelar',
                    indefinite: true,
                    type: 'is-warning',
                    onAction: () => {
                        window.open( data.s3link )
                    }
                })
            })
            .catch(err=>{
                console.warning(err)
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: 'Hubo un error: ' + err.toString(),
                    position: 'is-bottom-right'
                })
            })
        },
        _getStatusDisplay(status){
            return {
                'PROCESSING': 'Procesando',
                'PROCESSED': 'Procesado',
                'FILEUPLOADSUCCESS': 'Subido con éxito',
                'ERROR': 'Error',
                'FILEVALIDATIONERROR': 'Error',
                'PROCESSEDWITHERROR': 'Incompleto',
            }[status || 'PROCESSING']
        }
    }
}
</script>

<style>

</style>